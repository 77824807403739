
.commonPara-header-extend{
    background-image:linear-gradient(130deg, #87CEEB 14%, white 10%)!important
}

.commonPara-h1-extend{
    text-align: center !important;
}

.commonPara-h2-extend{
    text-align: center !important
}